<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <p class="text-title mon-bold">{{ texts.article.titleScreen }}</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NewArticleHeaderbarLayout",
  props: {
    texts: Object,
  },
};
</script>

<style scoped>
.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
</style>